@import "rsuite/dist/rsuite.css";

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; 
}

.container {
  display: flex;
  height: 100%;
}

.box {
  overflow-y: scroll !important;
}

.sidebar {
  width: 20%; 
  background-color: #f0f0f0;
  padding: 10px;
  border-right: 1px solid #ccc;
}

.map-wrapper {
  flex: 1; 
  position: relative;
}

#map {
  width: 100%;
  height: 100%;
}

.overlay-controls {
  position: absolute;
  top: 10px; 
  left: 10px; 
  background-color: white;
  padding: 10px;
  z-index: 2;
}

.info {
  bottom: 10px; 
  right: 10px; 
  background-color: white;
  padding: 10px;
  line-height: 18px;
  color: #555;
}

.info.legend {
  position: absolute;
  bottom: 10px; 
  right: 10px; 
  background-color: white;
  padding: 10px;
  z-index: 2;
  line-height: 18px;
  color: #555;
}

.info.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}


.tooltip-marker {
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1); 
}